<template>
    <div class="estimate-record">
        <estimate-record-not-draft v-if="isPublishSucceed" :pdfURL="recordModel?.pdfUrl"></estimate-record-not-draft>
        <div v-else>
            <estimate-record-edit v-if="isEditable" ref="editorRef" :initRecordModel="recordModel"
                :recordModel="recordModel" @onBaselineChanged="onBaselineChanged"></estimate-record-edit>
            <estimate-record-display v-else :recordModel="recordModel"></estimate-record-display>
        </div>

    </div>
</template>

<script>
import EstimateRecordEdit from "@c/record-frame/estimate-record/estimate-record-edit/estimate-record-edit.vue"
import EstimateRecordDisplay from "@c/record-frame/estimate-record/estimate-record-display/estimate-record-display.vue"
import EstimateRecordNotDraft from "@c/record-frame/estimate-record/estimate-record-not-draft.vue"
import { DateTool } from "@js/date-tool.js";
import { StatisticsReportModel } from "@js/model/statistics-report-model.js";
export default {
    components: {
        EstimateRecordDisplay,
        EstimateRecordEdit,
        EstimateRecordNotDraft,
    },

    props: {
        listItemModel: Object
    },

    data() {
        return {
            isEditable: undefined,
            recordModel: undefined,
            estimateChartArray: undefined,
            recordID: undefined,
        }
    },

    computed: {
        selectedRecordDurationModel() {
            return this.$store.state.selectedRecordDurationModel
        },

        isPublishSucceed() {
            //刚刚变成发布状态时，recordModel的pdfUrl还为null，此时恰好让后台根据display页面生成PDF
            return !!this.recordModel?.pdfUrl
        }
    },

    mounted() {
        //isEditable
        this.isEditable = (this.listItemModel.recordState == RECORD_STATUS.草稿 && !this.listItemModel.id)
        //recordModel
        this.recordID = this.listItemModel.id
        if (this.recordID) {
            this.getRecordDetail()
        } else {
            this.getInitDetail()
        }
        //重置selectedRecordDuration
        this.$store.commit("removeSelectedRecordDurationModel")
    },

    watch: {
        async selectedRecordDurationModel(value) {
            if (value?.changedBySelector) {
                //全不填或两块均填
                if (!this.$refs.editorRef.baselineRef.isValid) {
                    this.$message({
                        message: '基线数据不能为空',
                        offset: 2
                    });
                    return
                }
                this.recordModel.processedBy = "report"
                await this.getReportDetail()
                await this.getEstmateChartData()
                await this.getAssessmentTemplate()
            }
        }
    },

    methods: {
        onBarButtonClick(title) {
            switch (title) {
                case "编辑":
                    this.isEditable = true
                    break;
                //暂存和发布只在编辑时出现，可用$refs
                case "暂存":
                    const saveParams = this.composeEditParams(RECORD_STATUS.草稿)
                    this.editRecord(saveParams)
                    break;
                case "发布":
                    if (!this.selectedRecordDurationModel) {
                        this.$message({
                            message: '日期选择不能为空，请完善内容再发布记录',
                            offset: 2
                        });
                        return
                    }
                    if (!this.$refs.editorRef.baselineRef.isValid) {
                        this.$message({
                            message: '基线数据不能为空，请完善内容再发布记录',
                            offset: 2
                        });
                        return
                    }
                    const publishParams = this.composeEditParams(RECORD_STATUS.已发布)
                    this.editRecord(publishParams)
                    break;
                // 显示状态时出现
                case "撤销":
                    const cancelParams = this.composeEditParams(RECORD_STATUS.已撤销)
                    this.editRecord(cancelParams)
                    break;
                default:
                    break;
            }
        },

        async onBaselineChanged(isAuto = false) {
            this.recordModel.processedBy = isAuto ? null : "baseline"
            await this.getEstmateChartData()
            await this.getAssessmentTemplate()
        },

        async getInitDetail() {
            try {
                const recordModel = {}
                const params = {
                    uid: this.listItemModel.patientId,
                };
                //患者信息
                const patientModel = await this.$api.getPatientDetail(params);
                recordModel.name = patientModel?.name
                recordModel.weight = patientModel?.weight
                recordModel.height = patientModel?.height
                recordModel.bmi = patientModel?.bmi
                recordModel.age = patientModel?.age
                recordModel.sex = patientModel?.sex
                //基线数据
                const baseDataParams = {
                    userId: this.listItemModel.patientId
                }
                const baseDataModel = await this.$api.getBaseDataReport(baseDataParams)
                const reportArray = baseDataModel.sleepReport
                if (reportArray.length) {
                    const latestReport = getLatestReport(reportArray)
                    recordModel.baselineDataStartTime = latestReport?.reportTime
                    recordModel.baseAhi = latestReport.ahi
                    recordModel.baseAi = latestReport.ai
                    recordModel.baseHi = latestReport.hi
                    recordModel.baseOdi3 = latestReport.odi
                    recordModel.baseMinBloodOxygen = latestReport.spo2min
                    recordModel.baseAvgBloodOxygen = latestReport.spo2avg
                }
                //赋值
                this.recordModel = recordModel
                //自动查询基线数据
                if (baseDataModel.reportTime) {
                    const isAuto = true
                    this.onBaselineChanged(isAuto)
                }
            } catch (error) {
                this.$toast.showRed(error)
            }

            function getLatestReport(reportArray) {
                const timeArray = reportArray.map((item) => {
                    return DateTool.timeStrToMilliTimestamp(item.reportTime)
                })
                const latestTime = Math.max(...timeArray)
                const latestTimeIndex = timeArray.indexOf(latestTime)
                return reportArray[latestTimeIndex]
            }
        },

        async getRecordDetail() {
            try {
                const params = {
                    id: this.recordID
                }
                this.recordModel = await this.$api.getEstimateRecord(params)

                //全局更新记录时间
                this.$store.commit("saveSelectedRecordDurationModel", {
                    changedBySelector: false,
                    selectedDatesArray: this.recordModel.recordDateList
                })

            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        async getReportDetail() {
            const newRecordModel = { ...this.recordModel }
            try {
                const params = {
                    hospitalId: this.listItemModel.hospitalid,
                    uid: this.listItemModel.patientId,
                    day: this.selectedRecordDurationModel.selectedDatesArray ?? []
                };
                const data = await this.$api.getReportStatisticsDetail(params);
                this.statisticsReportModel = new StatisticsReportModel(data)
                newRecordModel.sn = this.statisticsReportModel?.deviceSN
                //数据
                newRecordModel.pressure90 = this.statisticsReportModel?.model.census.tpIn
                newRecordModel.exhalePressure90 = this.statisticsReportModel?.model.census.tpEx
                newRecordModel.cureMode = this.statisticsReportModel?.model.census.cureModel
                newRecordModel.avgAirLeakage = this.statisticsReportModel?.model.census.leakage
                newRecordModel.minBloodOxygen = this.statisticsReportModel?.model.census.spoMin
                newRecordModel.avgBloodOxygen = this.statisticsReportModel?.model.census.spoAvg
                newRecordModel.avgHeartRate = this.statisticsReportModel?.model.census.bpmAvg
                newRecordModel.maxHeartRate = this.statisticsReportModel?.model.census.bpmMax
                newRecordModel.ahi = this.statisticsReportModel?.model.census.ahi
                newRecordModel.csa = this.statisticsReportModel?.model.census.csa
                newRecordModel.ai = this.statisticsReportModel?.model.census.ai
                newRecordModel.csr = this.statisticsReportModel?.model.census.csr
                newRecordModel.hi = this.statisticsReportModel?.model.census.hi
                newRecordModel.biot = this.statisticsReportModel?.model.census.pb
                //依从性
                newRecordModel.totalDuration = DateTool.formatSeconds(this.statisticsReportModel?.model.census.longTime)
                newRecordModel.totalDay = this.statisticsReportModel?.model.census.dayTotal
                newRecordModel.useDay = this.statisticsReportModel?.model.census.dayUse
                const notUseDay = this.statisticsReportModel?.model.census.dayTotal - this.statisticsReportModel?.model.census.dayUse
                newRecordModel.notUseDay = isNaN(notUseDay) ? null : notUseDay
                newRecordModel.moreThan4HoursDay = this.statisticsReportModel?.model.census.longTimeAft
                newRecordModel.lessThan4HoursDay = this.statisticsReportModel?.model.census.longTimePre
                newRecordModel.allAvgUseTime = DateTool.formatSeconds(this.statisticsReportModel?.model.census.longTime / this.statisticsReportModel?.model.census.dayTotal)
                newRecordModel.avgUseTime = DateTool.formatSeconds(this.statisticsReportModel?.model.census.longTime / this.statisticsReportModel?.model.census.dayUse)
                newRecordModel.ahi5Day = this.statisticsReportModel?.model.census.ahiLt5
                //治疗参数
                newRecordModel.respiratorParameter = JSON.stringify(this.statisticsReportModel?.model.lastParameter)
                //图表数据
                newRecordModel.chartData = JSON.stringify(this.statisticsReportModel?.chartData)
            } catch (error) {
                newRecordModel.sn = null
                //数据
                newRecordModel.pressure90 = ""
                newRecordModel.exhalePressure90 = ""
                newRecordModel.cureMode = ""
                newRecordModel.avgAirLeakage = ""
                newRecordModel.minBloodOxygen = ""
                newRecordModel.avgBloodOxygen = ""
                newRecordModel.avgHeartRate = ""
                newRecordModel.maxHeartRate = ""
                newRecordModel.ahi = ""
                newRecordModel.csa = ""
                newRecordModel.ai = ""
                newRecordModel.csr = ""
                newRecordModel.hi = ""
                newRecordModel.biot = ""
                //依从性
                newRecordModel.totalDuration = ""
                newRecordModel.totalDay = null
                newRecordModel.useDay = null
                newRecordModel.notUseDay = null
                newRecordModel.moreThan4HoursDay = null
                newRecordModel.lessThan4HoursDay = null
                newRecordModel.allAvgUseTime = ""
                newRecordModel.avgUseTime = ""
                newRecordModel.ahi5Day = null
                //治疗参数(CPAP模式空值)
                const emptyParameter = {
                    aiAlarm: null,
                    apapMaxP: null,
                    apapMinP: null,
                    cpapP: null,
                    cureDelay: null,
                    cureMode: null,
                    depLevel: null,
                    depType: null,
                    heattubeTem: null,
                    humidifyLevel: null,
                    intelligentStart: null,
                    intelligentStop: null,
                    leakAlarm: null,
                    mask: null,
                    mvAlarm: null,
                    pipe: null,
                    startPressure: null,
                    temperatureUnit: null,
                    tvAlarm: null
                }
                newRecordModel.respiratorParameter = JSON.stringify(emptyParameter)
                //图表数据（统计天数空图表）
                const dayArray = this.selectedRecordDurationModel.selectedDatesArray ?? []
                const nullArray = new Array(dayArray.length).fill(null)
                const enmptyChartData = {
                    dayArray: this.selectedRecordDurationModel.selectedDatesArray ?? [],
                    useHourAbove4Array: [...nullArray],
                    useHourUnder4Array: [...nullArray],
                    pressure90Array: [...nullArray],
                    pressureMaxArray: [...nullArray],
                    pressureMinArray: [...nullArray],
                    aiArray: [...nullArray],
                    hiArray: [...nullArray],
                    csaArray: [...nullArray],
                    csrArray: [...nullArray],
                    pbArray: [...nullArray],
                    humidifyLevelArray: [...nullArray],
                    leakageAbove5Array: [...nullArray],
                    leakageUnder5Array: [...nullArray]
                }
                newRecordModel.chartData = JSON.stringify(enmptyChartData)
            }
            this.recordModel = newRecordModel
        },

        async getEstmateChartData() {
            if (!this.$refs.editorRef.baselineRef.isValid) {
                this.$message({
                    message: '基线数据不能为空，请完善内容再发布记录',
                    offset: 2
                });
                return
            }
            try {
                const startDate = this.$refs.editorRef.baselineRef.startDate
                const baselineDataStartTime = startDate ? DateTool.dateToStr(startDate) : ""
                let params = {
                    "patientId": this.listItemModel.patientId,
                    "baselineDataStartTime": baselineDataStartTime,
                    "ahi": this.$refs.editorRef.baselineRef.ahi,
                    "odi3": this.$refs.editorRef.baselineRef.odi3,
                    "ai": this.$refs.editorRef.baselineRef.ai,
                    "hi": this.$refs.editorRef.baselineRef.hi,
                    "avgBloodOxygen": this.$refs.editorRef.baselineRef.avgSPO2,
                    "minBloodOxygen": this.$refs.editorRef.baselineRef.minSPO2,
                    "recordDateList": this.selectedRecordDurationModel?.selectedDatesArray
                };
                const estimateChartArray = await this.$api.getEstimateChartArray(params);
                const newRecordModel = { ...this.recordModel }
                newRecordModel.adaptabilityAssessmentArr = JSON.stringify(estimateChartArray)
                this.recordModel = newRecordModel
            } catch (error) {

            }
        },

        async getAssessmentTemplate() {
            try {
                const startDate = this.$refs.editorRef.baselineRef.startDate
                const baselineDataStartTime = startDate ? DateTool.dateToStr(startDate) : ""
                const templateParams = {
                    "patientId": this.listItemModel.patientId,
                    "age": this.recordModel.age,
                    "baselineDataStartTime": baselineDataStartTime,
                    "baseAhi": this.$refs.editorRef.baselineRef.ahi,
                    "baseOdi3": this.$refs.editorRef.baselineRef.odi3,
                    "baseAi": this.$refs.editorRef.baselineRef.ai,
                    "baseHi": this.$refs.editorRef.baselineRef.hi,
                    "baseAvgBloodOxygen": this.$refs.editorRef.baselineRef.avgSPO2,
                    "baseMinBloodOxygen": this.$refs.editorRef.baselineRef.minSPO2,
                    "recordDateList": this.selectedRecordDurationModel?.selectedDatesArray,
                    "templateType": RECORD_TYPE.适宜性评估
                }
                const assessmentModel = await this.$api.getAssessmentTemplate(templateParams)
                const newRecordModel = { ...this.recordModel }
                newRecordModel.physicianAssessment = assessmentModel?.physicianAssessmentTemplate
                newRecordModel.technicianAssessment = assessmentModel?.technicianAutographTemplate
                this.recordModel = newRecordModel
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        async editRecord(params) {
            try {
                //提交修改
                this.recordID = await this.$api.saveEstimateRecord(params)
                //请求新数据
                this.recordModel = await this.$api.getEstimateRecord({ id: this.recordID })
                //显示展示页面
                this.isEditable = false
                //刷新record-bar
                const newListItemModel = { ...this.listItemModel }
                newListItemModel.id = this.recordModel.id
                newListItemModel.recordState = this.recordModel.recordState
                //刷新列表页
                this.$emit("listItemModelChanged", newListItemModel)
                //已发布处理
                if (params.recordState == RECORD_STATUS.已发布) {
                    this.$emit("recordPublished", this.recordID)
                }
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        composeEditParams(recordStatus) {
            switch (recordStatus) {
                //暂存和发布只在编辑时出现，可用$refs
                case RECORD_STATUS.草稿:
                case RECORD_STATUS.已发布:
                    const startDate = this.$refs.editorRef.baselineRef.startDate
                    const baselineDataStartTime = startDate ? DateTool.dateToStr(startDate) : ""
                    return {
                        "id": this.recordID,
                        "recordState": recordStatus,
                        "title": this.$refs.editorRef.headRef.title,
                        "sn": this.$refs.editorRef.headRef.recordModel.sn,
                        "recordDateList": this.selectedRecordDurationModel?.selectedDatesArray,
                        "patientId": this.listItemModel.patientId,
                        "name": this.$refs.editorRef.userInfoRef.userName,
                        "weight": this.$refs.editorRef.userInfoRef.weight,
                        "height": this.$refs.editorRef.userInfoRef.height,
                        "bmi": this.$refs.editorRef.userInfoRef.bmi,
                        "age": this.$refs.editorRef.userInfoRef.age,
                        "sex": this.$refs.editorRef.userInfoRef.gender,
                        "baseAhi": this.$refs.editorRef.baselineRef.ahi,
                        "baseOdi3": this.$refs.editorRef.baselineRef.odi3,
                        "baseAi": this.$refs.editorRef.baselineRef.ai,
                        "baseHi": this.$refs.editorRef.baselineRef.hi,
                        "baselineDataStartTime": baselineDataStartTime,
                        "baseAvgBloodOxygen": this.$refs.editorRef.baselineRef.avgSPO2,
                        "baseMinBloodOxygen": this.$refs.editorRef.baselineRef.minSPO2,
                        "ahi": this.$refs.editorRef.dataRef.ahi,
                        "csa": this.$refs.editorRef.dataRef.csa,
                        "ai": this.$refs.editorRef.dataRef.ai,
                        "csr": this.$refs.editorRef.dataRef.csr,
                        "hi": this.$refs.editorRef.dataRef.hi,
                        "biot": this.$refs.editorRef.dataRef.biot,
                        "pressure90": this.$refs.editorRef.dataRef.pressure90,
                        "exhalePressure90": this.$refs.editorRef.dataRef.exhalePressure90,
                        "cureMode": this.$refs.editorRef.dataRef.cureMode,
                        "avgAirLeakage": this.$refs.editorRef.dataRef.avgAirLeakage,
                        "minBloodOxygen": this.$refs.editorRef.dataRef.minBloodOxygen,
                        "avgBloodOxygen": this.$refs.editorRef.dataRef.avgBloodOxygen,
                        "avgHeartRate": this.$refs.editorRef.dataRef.avgHeartRate,
                        "maxHeartRate": this.$refs.editorRef.dataRef.maxHeartRate,
                        "totalDuration": this.$refs.editorRef.complianceRef.totalTime,
                        "totalDay": this.$refs.editorRef.complianceRef.totalDay,
                        "useDay": this.$refs.editorRef.complianceRef.usedDay,
                        "notUseDay": this.$refs.editorRef.complianceRef.unusedDay,
                        "moreThan4HoursDay": this.$refs.editorRef.complianceRef.over4HoursDay,
                        "lessThan4HoursDay": this.$refs.editorRef.complianceRef.under4HoursDay,
                        "allAvgUseTime": this.$refs.editorRef.complianceRef.averageTotalTime,
                        "avgUseTime": this.$refs.editorRef.complianceRef.averageUsedTime,
                        "ahi5Day": this.$refs.editorRef.complianceRef.ahiUnder5Day,
                        "respiratorParameter": this.$refs.editorRef.recordModel.respiratorParameter,
                        "chartData": this.$refs.editorRef.recordModel.chartData,
                        "adaptabilityAssessmentArr": this.$refs.editorRef.recordModel.adaptabilityAssessmentArr,
                        "technicianAssessment": this.$refs.editorRef.technicianRef.content,
                        "physicianAssessment": this.$refs.editorRef.doctorRef.content,
                        "technicianAutograph": this.$refs.editorRef.technicianRef.signature,
                        "physicianAutograph": this.$refs.editorRef.doctorRef.signature,
                    }
                case RECORD_STATUS.已撤销:
                    const params = { ...this.recordModel }
                    params.recordState = RECORD_STATUS.已撤销
                    return params
                default:
                    break;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.estimate-record {
    width: 100%;
    // height: 969px;
    background-color: white;

}
</style>
